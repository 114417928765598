<template>
  <div>
    <ArchiveLinkV2></ArchiveLinkV2>
    <DeleteLinkV2></DeleteLinkV2>
    <CreateLinkV2></CreateLinkV2>
    <div class="pb-6 pt-[84px] px-6 w-full h-screen bg-[#F7F7FC]">
      <div class="grid_box_shadow bg-white w-full h-full bg-opacity-80 rounded-lg">
        <div class="flex flex-col justify-between h-full">
          <div :class="{'border-b': getLinks.total_items > getLinks.limit}" class="flex flex-col flex-grow h-full border-[#ECEEF5]">
            <LinksFiltersV2></LinksFiltersV2>
            <LinksTableV2></LinksTableV2>
          </div>
          <LinksPaginationV2></LinksPaginationV2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default ({
  'name': 'Links',
  components: {
    LinksFiltersV2: () => import('@/views/pages/links/filters/LinksFiltersV2.vue'),
    ArchiveLinkV2: () => import('@/views/pages/links/dialogs/ArchiveLinkV2.vue'),
    DeleteLinkV2: () => import('@/views/pages/links/dialogs/DeleteLinkV2.vue'),
    LinksPaginationV2: () => import('@/views/pages/links/links-component/LinksPaginationV2.vue'),
    LinksTableV2: () => import('@/views/pages/links/links-component/LinksTableV2.vue'),
    CreateLinkV2: () => import('@/views/pages/links/dialogs/CreateLinkV2.vue'),
  }
})
</script>
